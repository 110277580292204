<template>
  <div style=" background: #fff;">
    <div class="header">
        <div class="header_left">
            <img class="header_left_logo" src="../assets/logo.png" />
            <div class="header_left_right">
                <ul class="header_left_right_ul">
                    <li class="header_left_right_ul_li"><router-link to="/">首页</router-link></li>
                    <li class="header_left_right_ul_li"><router-link to="/Goods">服务信息列表</router-link></li>
                    <li class="header_left_right_ul_li"><router-link to="/JoinIn">立即发布服务</router-link></li>
                    <li class="header_left_right_ul_li"><router-link to="/Information">行业资讯</router-link></li>
                    <li class="header_left_right_ul_li"><router-link to="/AboutUs">关于我们</router-link></li>
                </ul>
            </div>
        </div>
        <div class="header_right_login" @click="Initlogin">登录/注册</div>
    </div>
    <LoginPage ref="loginpage"></LoginPage>
  </div>
</template>

<script>
import { ref } from 'vue';
import LoginPage from './LoginPage.vue';
export default {
  components: {
    LoginPage
  },
  setup() {
    let loginpage = ref()

    function Initlogin(){
        loginpage.value.InitdialogVisible()
    }
    return {
        loginpage,
        Initlogin
    };
  },
};
</script>


<style scoped>
a{
    text-decoration: none;
    color: #000;
}
.header{
    width: 1200px;
    margin: 20px auto 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}
.header_left{
    display: flex;
    align-items: center;
}
.header_left_logo{
    width: 181px;
    height: 55px;
    margin-right: 30px;
}
.header_left_right{
    display: flex;
    align-items: center;
}
.header_left_right_ul{
    cursor: pointer;
    display: flex;
    list-style: none;
}
.header_left_right_ul_li{
    margin-right: 30px;
    font-size: 16px;
    font-weight: 600;
}
.header_left_right_ul_li:last-child{
    margin-right: 0px;
}
.header_right_login{
    margin-left: 30px;
    color: rgb(30, 148, 255);
    font-size: 16px;
    cursor: pointer;
}

.Popup_top{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
}
.Popup_top_item{
    flex: 1;
    text-align: center;
    font-size: 15px;
    padding-bottom: 10px;
    cursor: pointer;
}
.Popup_top_select{
    color: rgb(30, 148, 255);
}
.fromBox{
    width: 300px;
    margin: 20px auto;
}
.Submit_Info{
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>