<template>
  <div class="Box">
    <div class="Box_item">
      <div class="Box_item_left">
        <div class="Box_item_left_main">
          <img src="../assets/address.png" />
          <div>广州中杰信息科技有限公司</div>
        </div>
        <div class="Box_item_left_main">
          <img src="../assets/address1.png" />
          <div>公司地址:广州市天河区东莞庄路2号财润国际大厦A座3022</div>
        </div>
        <div class="Box_item_left_main">
          <img src="../assets/phone.png" />
          <div>公司电话:020-28185216</div>
        </div>
        <div class="Box_item_left_main">
          <img src="../assets/mailbox.png" />
          <div>公司邮箱:pmlily@wnoon.cn</div>
        </div>
      </div>
      <!-- <div class="Box_item_right">
        <div>关注我们</div>
        <img src="../assets/code.jpg" />
      </div> -->
    </div>
    <div class="code" @click="Gomiit">CopyRight©2012-2020 广州中杰信息科技有限公司 All Rights Reserved.  粤ICP备15057588号-3</div>
  </div>
</template>

<script>
export default {
  components: {},
  setup() {
    function Gomiit(){
      window.open('https://beian.miit.gov.cn/')
    }
    return {
      Gomiit
    };
  },
};
</script>

<style scoped>
.Box {
  background: url(../assets/footerbg.png);
  padding: 50px 0px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.Box_item{
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.Box_item_left{

}
.Box_item_left_main{
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.Box_item_left_main img{
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.Box_item_left_main div{
  color: #fff;
  font-size: 13px;
}

.Box_item_right div{
  color: #fff;
  font-size: 13px;
  text-align: center;
  margin-bottom: 10px;
}
.Box_item_right img{
  width: 100px;
  height: 100px;
}

.code{
  text-align: center;
  color: #fff;
  font-size: 15px;
  margin-top: 20px;
}
</style>